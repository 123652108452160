import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router';

export default function ProductPayment() {
    const params = useParams();
    const navigate = useNavigate();
    const [readyToRedirect, setReadyToRedirect] = useState(false);

    useEffect(() => {
        if( params.productName === 'recomended') window.open(process.env.REACT_APP_STRIPE_URL_RECOMENDED_PRODUCT, '_blank');
        if( params.productName === 'professional') window.open(process.env.REACT_APP_STRIPE_URL_PROFESSIONAL_PRODUCT, '_blank');
        setReadyToRedirect(true);
    }, [])

    useEffect(() => {
        if(readyToRedirect) navigate('/products/'+params.productName);
    }
    , [readyToRedirect])

  return (
    <div className='max-w-[1300px] w-full h-full mx-auto'>Will redirect</div>
  )
}

