import React from 'react';
import './index.css';
import App from './App';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import i18next from './services/i18next_translation';
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Helmet>
        <title>HiDataManagement</title>
        <meta name="description" content="Optimized software helping you keeping your images,files and data safe and organized and accessable. Ideal for profesionals that need a fast and easy enviroment to work with with their content." />
        <meta name="keywords" content="data, images, files, software, management, organization, safe, secure, fast, easy, accessable, profesionals, optimized, highdatamanagement" />
        <meta property='og:title' content='HiDataManagement' />
        <meta property='og:images' content='https://www.hidatamanagment.com/logo512.png' />
        <meta property='og:description' content='Optimized software helping you keeping your images,files and data safe and organized and accessable. Ideal for profesionals that need a fast and easy enviroment to work with with their content.' />
        <meta property='og:url' content='https://www.hidatamanagment.com' />
        <meta property='og:type' content='website' />
        <meta property='og:site_name' content='HiDataManagement' />
        <meta property='og:locale' content='en_US' />
        <meta property='og:locale:alternate' content='el_GR' />

        {/* <meta name="twitter:title" content={Title of your page} />
        <meta name="twitter:description" content={Description of your page} />
        <meta name="twitter:image" content={URL of the image you want to use} />
        <meta name="twitter:card" content="summary_large_image" /> */}
      </Helmet>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);
