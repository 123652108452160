import React from 'react'

export default function ErrorPage() {
    return (
        <div className='max-w-[1300px] w-full h-full mx-auto'>
            <h1>404</h1>
            <p>Can't access page</p>
        </div>
    )
}
