
export function PrimaryButton({text,className="",onClick=(e)=>{}}){
    return <button 
    className={`${className} border-2 border-secondary hover:border-primary bg-primary hover:bg-secondary py-2 px-4 rounded-full text-white hover:text-primary active:bg-opacity-80`}
    onClick={onClick}>{text}</button>;
}

export function SecondaryButton({text,className="",onClick=(e)=>{}}){
    return <button 
    className={`${className} border-2 border-primary hover:border-white bg-white hover:bg-secondary py-2 px-4 rounded-full text-primary hover:text-white`}
    onClick={onClick}>{text}</button>;
}