import React from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../components/buttons";
import { useTranslation } from "react-i18next";

function ProductCard({ className, name, price, description, to }) {
  const navigate = useNavigate();
  return (
    <div className="relative">
      <button
        onClick={(e) => navigate(to)}
        className={
          className +
          " relative hover:cursor-pointer hover:opacity-75 border-2 max-w-[300px] rounded-[1rem] p-4 shadow-md shadow-primary"
        }
      >
        <h2 className="text-[20px] font-bold text-center underline">{name}</h2>
        <img
          src="logo192.png"
          alt="app icon"
          className="mx-auto my-2 rounded-[1rem] shadow-lg border-4 border-grey-200 bg-white w-[100px] h-[100px]"
        />
        <h3 className="my-2">{price} €</h3>
        <p className="text-[12px]">{description}</p>
      </button>
      <PrimaryButton
        text={"Buy Now"}
        className="absolute bottom-2 right-2"
        onClick={(e) => navigate(to + "/payment")}
      />
    </div>
  );
}

export default function ProductsContent() {
  const [t, i18n] = useTranslation("products");
  return (
    <div className="max-w-[1300px] w-full h-full mx-auto">
      <h1>Our Products</h1>
      <div className="flex gap-4 p-4 justify-center items-center">
        <ProductCard
          name={"Recomended"}
          price={229.99}
          description={
            "This is a fully featured version of the product, contains all the functionality u will need"
          }
          className={"h-[300px] bg-gradient-to-tr from-white to-primary"}
          to={"recomended"}
        />

        <ProductCard
          name={"Professional"}
          price={359.99}
          description={
            "This is the fully featured product without limitations, unlimited databases and unlimited space"
          }
          className={"h-[300px] bg-gradient-to-tr from-white to-primary"}
          to={"professional"}
        />
      </div>
      <section id="products" className="relative">
        <img src="" alt="" />
        <h1>{t("title")}</h1>
        <p>{t("content")}</p>        
      </section>

    </div>
  );
}
