import React, { useEffect, useState } from "react";
import "./Layout.css";
import { Link, Outlet } from "react-router-dom";
import { auth } from "../../services/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useTranslation } from "react-i18next";

export default function Layout() {
  const [t, i18n] = useTranslation("global");

  const [language, setLanguage] = useState("us");
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  const [popup, setPopup] = useState("none");
  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (!e.target.className.includes("popup")) {
        setPopup("none");
      }
    });
  }, []);

  const [isLoggedIn, setIsLoggedIn] = useState(auth.currentUser);
  const loggOut = () => {
    setIsLoggedIn(false);
    auth.signOut();
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, []);

  return (
    <div className="mx-auto w-full">
      <nav className="flex flex-col z-10 bg-white relative">
        <div className="h-[3rem] flex items-center gap-2 align-middle justify-between px-4 shadow-secondary shadow-md">
          <Link
            to="/"
            className="h-full aspect-square flex items-center gap-2 align-middle justify-between"
          >
            <img
              className="h-full aspect-square my-auto cursor-pointer"
              src="/logo192.png"
              alt=""
            />
          </Link>

          <div className="flex h-full items-center">
            <Link
              to="whoarewe"
              className="hover:bg-secondary px-2 h-full flex items-center"
            >
              {t("sidebar.whoarewetext")}
            </Link>
            <Link
              to="products"
              className="hover:bg-secondary px-2 h-full flex items-center"
            >
              {t("sidebar.productstext")}
            </Link>
            <Link
              to="videos"
              className="hover:bg-secondary px-2 h-full flex items-center"
            >
              {t("sidebar.videostext")}
            </Link>

            {isLoggedIn && (
              <Link
                to="passwords"
                className="hover:bg-secondary px-2 h-full flex items-center"
              >
                {t("sidebar.passwordstext")}
              </Link>
            )}

            <Link
              to="contact"
              className="hover:bg-secondary px-2 h-full flex items-center"
            >
              {t("sidebar.contacttext")}
            </Link>
          </div>

          <dir className="flex gap-2 items-center ">
            <span className={`fi fi-${language}`}></span>
            <select
              className="w-[3rem]"
              onChange={(e) => changeLanguage(e.target.value)}
              value={language}
            >
              <option value="gr">EL</option>
              <option value="us">EN</option>
            </select>

            {isLoggedIn ? (
              <div className="popup relative">
                <img
                  src={auth.currentUser.photoURL}
                  alt=""
                  className="popup rounded-full h-[3rem] aspect-square border-gray-200 hover:border-gray-300 border-2 cursor-pointer"
                  onClick={(e) => setPopup("profile")}
                />

                {popup === "profile" && (
                  <div
                    className={`popup absolute -right-2 top-12 bg-green-400 my-auto px-4 rounded-full text-center whitespace-nowrap cursor-pointer text-white border-gray-200 hover:border-gray-300 border-2`}
                    onClick={(e) => loggOut()}
                  >
                    Sign Out
                  </div>
                )}
              </div>
            ) : (
              <div
                className={`hover:bg-amber-300 bg-amber-400 px-4 rounded-full text-center cursor-pointer text-white border-rose-200 hover:border-rose-300 border-2`}
                onClick={(e) =>
                  signInWithPopup(auth, new GoogleAuthProvider())
                    .then((result) => {
                      // Handle successful sign-in
                      console.log("Signed in successfully:", result.user);
                    })
                    .catch((error) => {
                      // Handle errors
                      console.error("Error signing in:", error.message);
                    })
                }
              >
                Login
              </div>
            )}
          </dir>
        </div>
      </nav>

      <Outlet />
    </div>
  );
}
