import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../components/buttons";
import "./HomeContent.css";

export default function HomeContent() {
  const [t, i18n] = useTranslation("home");
  const navigate = useNavigate();

  return (
    <>
      <div className="absolute left-0 right-0 h-[60vh] bg-gradient-to-tr from-white to-primary"></div>
      <main className="max-w-[1300px] w-full h-full mx-auto">
        <section
          id="hero"
          className="relative flex justify-start items-center h-[60vh]"
        >
          <div className="relative h-[13rem] w-full mx-[4rem] flex flex-col justify-between z-10">
            <img
              src="images/mainView.png"
              alt="desktop view"
              className="desktop-image absolute right-1 z-0 w-[17rem] h-[12rem] border-2 border-black rounded"
            />
            <div className="desktop-shadow absolute right-1 z-0 w-[17rem] h-[1rem] bg-black"></div>

            <div className="flex flex-col z-10">
              <h1 className="font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
                High Data Managment
              </h1>
              <p>A fast and easy content management software for creators</p>
            </div>
            <div className="flex gap-4 z-10">
              <PrimaryButton
                text={"Get software"}
                onClick={() => navigate("/products")}
                className="text-[1.5rem]"
              />
              <SecondaryButton
                text={"Learn more"}
                onClick={() => navigate("/")}
                className="text-[1.5rem]"
              />
            </div>
          </div>
        </section>
        <section id="home" className="relative">
          <img src="" alt="" />
          <h1>{t("home-title")}</h1>
          <p>{t("home-content")}</p>
        </section>
        <section id="about" className="relative">
          <img src="" alt="" />
          <h1>{t("about-title")}</h1>
          <p>{t("about-content")}</p>
        </section>
        <section id="program-description" className="relative">
          <img src="" alt="" />
          <h1>{t("program-description-title")}</h1>
          <p>{t("program-description-content")}</p>
        </section>
        <section id="products" className="relative">
          <img src="" alt="" />
          <h1>{t("products-title")}</h1>
          <p>{t("products-content")}</p>
        </section>
      </main>
    </>
  );
}
