import { useTranslation } from "react-i18next";

export default function WhoareweContent() {
  const [t] = useTranslation("whoarewe");
  return (
    <div className="max-w-[1300px] w-full h-full mx-auto">
      <h1>{t("WhoWeAre")}</h1>
      <p>{t("WhoWeAreMainText")}</p>
      <h1>{t("programPurposeTitle")}</h1>
      <p>{t("programPurpose")}</p>
    </div>
  );
}
