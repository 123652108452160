import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from '../hooks';

export default function ContactContent() {
  return (
    <div>
      <ContactForm />
    </div>
  );
}

function ContactForm() {
  const { user } = useAuth();
  const [t, i18n] = useTranslation("global");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  async function onSubmit(e) {
    e.preventDefault();
    // validate inputs
    if (!firstName || !lastName || !address || !message) {
      alert("Please fill in all fields");
      return;
    }
    // send email
    const data = { 
      customerFirstName: lastName,
      customerLastName: firstName,
      customerPhone: phone,
      customerAddress: address,
      message: message,
    };
    const token = await user.getIdToken();

    // // make the request to the server
    fetch("https://us-central1-dad-web-76156.cloudfunctions.net/sentMail", {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((data) => {
        console.log("Success data sent");
        clearForm();
      })
      .catch((error) => console.error("Error:", error));
  }

  function clearForm() {
    setFirstName("");
    setLastName("");
    setAddress("");
    setPhone("");
    setMessage("");
  }

  return (
    <form onSubmit={onSubmit} className="max-w-[1300px] w-full h-full mx-auto mt-8">
      <div className="flex gap-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="firstName">
            {t("First Name")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            type="text"
            placeholder={t("Enter your first name")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastName">
            {t("Last Name")}
          </label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="lastName"
            type="text"
            placeholder={t("Enter your last name")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="address">
          {t("Address")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="address"
          type="text"
          placeholder={t("Enter your address")}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
          {t("Phone")}
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="phone"
          type="text"
          placeholder={t("Enter your phone number")}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
          {t("Message")}
        </label>
        <textarea
          className="shadow appearance-none border rounded w-full h-[20rem] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="message"
          placeholder={t("Enter your message")}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        {t("Send")}
      </button>
    </form>
  );
}
