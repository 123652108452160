import global_us from '../translations/us/global.json';
import global_gr from '../translations/gr/global.json';
import home_us from '../translations/us/home.json';
import home_gr from '../translations/gr/home.json';
import products_us from '../translations/us/products.json';
import products_gr from '../translations/gr/products.json';
import whoarewe_us from '../translations/us/whoarewe.json';
import whoarewe_gr from '../translations/gr/whoarewe.json';
import i18next from 'i18next';

i18next.init({
    interpolation: { escapeValue: false },
    lng: 'us',
    resources: {
        us: {
            global: global_us,
            home: home_us,
            products: products_us,
            whoarewe: whoarewe_us,
        },
        gr: {
            global: global_gr,
            home: home_gr,
            products: products_gr,
            whoarewe: whoarewe_gr,
        }
    },
});

export default i18next;