import { useTranslation } from "react-i18next";

export default function PasswordsContent() {
  const [t, i18n] = useTranslation("global");

  async function requestForAccessKey(){
    // ask the server for the access key
    // in the body of the request must be the email of the user and the signature of the firebase auth so i can know that is not corrupted

  }

  function handleOptionChange(e){
    console.log(e.target.value);
  }

  return <div className="max-w-[1300px] w-full h-full mx-auto mt-8">
    
    <h1>{t("maincontent.passwordstext")}</h1>

    <h2>description of the process</h2>

    <form action="">
    <label htmlFor="options">Select the version u have downloaded and want to activate:</label>
      <select id="options" value={"option1"} onChange={handleOptionChange}>
        <option value="">Select...</option>
        <option value="option2">Product Recomended</option>
        <option value="option3">Product Profesional</option>
      </select>

      <label htmlFor="generated-code"></label>
      <input type="text" placeholder="generated code from program" id="generated-code"/>

      <button type="submit" onClick={e=>requestForAccessKey()}>Request for access key</button>

    </form>
  
  
  
  
  </div>;
}
