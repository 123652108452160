import React from "react";
import "./ViedosContent.css";

export default function ViedosContent() {
  return (
    <div className="max-w-[1300px] w-full h-full mx-auto">
      <h1>Youtube Channel</h1>
      YouTube channel{" "}
      <a
        href="https://studio.youtube.com/channel/UCDY9tO2ZrS6rftU2oOQIyYA/videos"
        className="text-blue-500 underline hover:text-blue-700"
      >
        here
      </a>
    </div>
  );
}
