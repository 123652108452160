import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { auth } from "../../services/firebase";
import { SecondaryButton } from "../../components/buttons";
import { useLocation, useNavigate } from "react-router-dom";

export default function SignInContent() {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    // Parse the query parameters from the location object
    const queryParams = new URLSearchParams(location.search);

    // Access individual query parameters
    const param1 = queryParams.get("redirect");
    if (param1 === null) return;
    setRedirectTo(param1);
  }, [location.search]);

  return (
    <div className="max-w-[1300px] w-full h-full mx-auto flex flex-col justify-center text-center mt-10">
      <h1>One click Google Login</h1>
      <SecondaryButton
        text={"Google Login"}
        className="w-[200px] h-[50px] mx-auto mt-10"
        onClick={(e) =>
          signInWithPopup(auth, new GoogleAuthProvider())
            .then((result) => {
              // Handle successful sign-in
              console.log("Signed in successfully:", result.user);
              navigate(redirectTo || "/");
            })
            .catch((error) => {
              // Handle errors
              console.error("Error signing in:", error.message);
            })
        }
      />
    </div>
  );
}
