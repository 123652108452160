import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebaseConfig from "./credentials.json";
import { collection, getDocs, getFirestore } from "firebase/firestore";

// Initialize Firebase
export const firebase = initializeApp(firebaseConfig);

// Get Auth
export const auth = getAuth(firebase);


const db = getFirestore(firebase);

export const getMyProucts = async () => {
    // current user
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not logged in!");
    }

    // get the products
    const products = [];
    const querySnapshot = await getDocs(collection(db, "users", user.email, "products"));
    querySnapshot.forEach((doc) => {
        console.log(doc);
        products.push(doc.data());
    });

    return products;
}

