import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout/Layout';
import PrivateRoute from './components/PrivateRoute';
import HomeContent from './pages/HomeContent/HomeContent.jsx';
import ContactContent from './pages/ContactContent.jsx';
import EULAContent from './pages/EULAContent.jsx';
import PasswordsContent from './pages/privatePages/PasswordsContent.jsx';
import WhoareweContent from './pages/WhoareweContent.jsx';
import ErrorPage from './pages/ErrorPage';
import ProductsContent from './pages/ProductsContent.jsx';
import ViedosContent from './pages/ViedosContent/ViedosContent.jsx';
import { AuthProvider } from './contexts/AuthContext.js';
import SignInContent from './pages/SignInContent/SignInContent.jsx';

import ProductPayment from './pages/ProductPayment.jsx';

const ProductRecomendedContent = lazy(() => import('./pages/privatePages/ProductRecomendedContent.jsx'));
const ProductProfesionalContent = lazy(() => import('./pages/privatePages/ProductProfesionalContent.jsx'));

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<HomeContent />} />
              <Route path="eula" element={<EULAContent />} />
              <Route path="whoarewe" element={<WhoareweContent />} />
              <Route path="products" element={<ProductsContent />} />
              <Route path="videos" element={<ViedosContent />} />

              <Route path="contact" element={<PrivateRoute />}>
                <Route index element={<ContactContent />} />
              </Route>
              
              <Route path="products/*" element={<PrivateRoute />}>
                <Route path="recomended" element={<ProductRecomendedContent />} />
                <Route path="professional" element={<ProductProfesionalContent />} />

                <Route path=":productName/payment" element={<ProductPayment />} />
              </Route>

              <Route path="passwords" element={<PrivateRoute />}>
                <Route index element={<PasswordsContent />} />
              </Route>

              <Route path="sign_in" element={<SignInContent />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
